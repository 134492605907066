import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Grid, Typography, Box, TextField, Button, FormControl, Select, MenuItem, IconButton, FormHelperText } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CancelIcon from "@material-ui/icons/Cancel";
// @ts-ignore
import bgImage from "../../blocks/analytics/assets/bg.png";
// @ts-ignore
import sideImage from "../../blocks/analytics/assets/sideImg.png"
// @ts-ignore
import logoImage from "../../blocks/analytics/assets/logo.png"
// @ts-ignore
import crossEyeImage from "../../blocks/analytics/assets/crossEye.png"
// @ts-ignore
import eyeImage from "../../blocks/analytics/assets/eye.png"
import { Formik } from "formik";
import * as Yup from "yup";
import { JoinMeetingI, MeetingTypeI, LoginMeetingType } from "../../blocks/visualanalytics/src/Interface";
interface InputValue {
    meetingLink: string;
    password: string;
    type_id: string;
    role_id: string;
}

const emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$");

const formikErrorMessage = (formik: any, fieldName: any) => {
    return (formik.touched[fieldName] && formik.errors[fieldName]
        ? <div style={{ color: "red", fontFamily: "SFPro-Regular", margin: "3px 0" }}>{formik.errors[fieldName]}</div>
        : null);
}

const formikErrorClassCheck = (formik: any, fieldName: any) => {
    return `form-control ${formik.errors[fieldName] ? 'is-invalid' : formik.touched[fieldName] && !formik.errors[fieldName] ? 'is-valid' : ''}`;
}

export interface JoinMeetingProps {
    level?: number,
    cancelJoin: () => void;
    joinMeetingAxios: (values: any) => void;
    meetingTypesData: LoginMeetingType[];
    getRoleFunc: (id: number) => void;
    roleTypesData: MeetingTypeI[];
}
interface UserData {
    id: number;
    first_name: string;
}


const JoinMeetingPopup = ({ level, cancelJoin, joinMeetingAxios, meetingTypesData, getRoleFunc, roleTypesData }: JoinMeetingProps) => {
    const classes = useStyles();
    // imageLoading: boolean;
    // password: boolean;
    // meetingType: string;
    // roleType: string;
    const [password, setPassword] = useState<boolean>(true);
    const [meetingType, setMeetingType] = useState<string>("");
    const [roleType, setRoleType] = useState<string>("");
    const [values, setValues] = useState<InputValue>({
        meetingLink: "",
        password: "",
        type_id: "",
        role_id: "",
    });
    const [inputError, setInputError] = useState<any>({});
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [userName, setUserName] = useState<UserData[]>([]);
    const [joinMeetingResponse, setJoinMeetingResponse] = useState<JoinMeetingI>({
        port: -1,
        meeting_name: "",
    });
    // const [meetingName, setMeetingName] = useState<string>("");

    const handleChange = (event: any, name: string) => {
        setValues({
            ...values,
            [name]: event.target.value,
        });
    }


    const showPassword = (passoword: boolean) => {
        setPassword(!passoword)
    }
    const handleMeetingTypeChange = (event: any) => {
        setMeetingType(event.target.value as string)
        getRoleFunc(event.target.value)
        setValues({ ...values, type_id: event.target.value })
        setRoleType("");
    }
    const handleRoleTypeChange = (event: any) => {
        setRoleType(event.target.value as string)
        setValues({ ...values, role_id: event.target.value })
    }
    const fieldValidation = () => {
        const passwordRegExp = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%*?&]{8,}$/;
        let error: any = {}
        if (!values.meetingLink) {
            error.meetLink = "Please provide meeting link"
        }
        // if (values.password.length < 8) {
        //     error.password = "Your password must be at least 8 characters";
        // }
        if (!meetingType) {
            error.type = "Please provide meeting type"
        }
        if (!roleType) {
            error.role = "Please provide your role"
        }
        if (error && Object.keys(error).length === 0 && Object.getPrototypeOf(error) === Object.prototype) {
            setInputError({});
            // console.log("I am success validation")
            // joinMeetingFunc(values);
            joinMeetingAxios(values);
        }
        else {
            setInputError(error)
        }
    }

    useEffect(() => {
        // Retrieve data from local storage
        const storedData = localStorage.getItem('userDetails');
    
        // Parse the data and set it in the state
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setUserName(parsedData);
        }
      }, []);

    
    return (
        <>
            <div className={classes.blur_effect} />
            <div className={"gridBoxjoinMetting"+" "+classes.main_wrapper}>
                <div style={{ margin: "0 -2px 0 25px", width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                     className = "closeModal"
                        onClick={() => cancelJoin()}
                        style={{
                            height: '15px',
                            width: '15px',
                            borderRadius: '50%',
                        }}
                    >
                        <CancelIcon style={{ color: "#ffffff", cursor: "pointer" }} />
                    </IconButton>
                </div>
                <Grid container spacing={0} style={{ border: "1px solid #2d2c2c", borderRadius: "10px" }}>
                    <Grid className="dNonegrid" item sm={5} xs={12} md={5} style={{ position: "relative", height: "100%" }}>
                        <img src={sideImage} style={{ borderRadius: "10px 0 0 10px", }} width="100%" height="100%" alt="No_image_found" />
                        <img src={logoImage} style={{ position: "absolute", top: "5%", left: "30%" }} width="40%" alt="No_image_found" />
                    </Grid>
                    <Grid item md={7} sm={7} xs={12} style={{ padding: "3% 6%", backgroundColor: '#000', borderRadius: "0 10px 10px 0" }} >

                        {userName.map(user => (
                            <Typography key={user.id} style={{textTransform:"capitalize", color: "#ffffff", fontSize: "25px", fontFamily: "SFPro-Bold", marginBottom: "10px" }}>
                                Hi, {user.first_name}
                            </Typography>
                        ))}
                        
                        {/* @ts-ignore */}
                        <Box>
                            {/* <Formik
                                initialValues={{
                                    meetingLink: "",
                                    password: "",
                                    type_id: "",
                                    role_id: ""
                                }}
                                enableReinitialize
                                validationSchema={Yup.object().shape({
                                    meetingLink: Yup.string()
                                        // .matches(emailReg, 'Email is not valid')
                                        // .email("Invalid email")
                                        .required("Meeting link is required")
                                        .max(200, "maximum limit 200"),
                                    password: Yup.string()
                                        .max(8, "maximum length is 8"),
                                    type_id: Yup.string()
                                        .required("Meeting type is required"),
                                    role_id: Yup.string()
                                        .required("Meeting role is required"),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    console.log("Calling onSubmit")
                                    joinMeetingFunc(values)
                                    resetForm({
                                        values: {
                                            meetingLink: "",
                                            password: "",
                                            type_id: "",
                                            role_id: ""
                                        }
                                    })
                                }}
                            >
                                {formikProps => {
                                    const {
                                        values,
                                        // setFieldValue,
                                        handleChange,
                                        handleSubmit,
                                        touched,
                                        errors
                                    } = formikProps;
                                    return ( */}
                            <form style={{ marginBottom: "1rem" }}>
                                {/* @ts-ignore */}
                                <Box>
                                    {/* @ts-ignore */}
                                    <Box style={{ marginBottom: "13px" }}>
                                        <Grid container spacing={3} justifyContent="space-between">
                                            <Grid item md={12} xs={12}>
                                                <Typography
                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        color: '#ffffff',
                                                        marginBottom: "0.6rem",
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Enter meeting link
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    id="meetingLink"
                                                    name="meetingLink"
                                                    // className={formikErrorClassCheck(formikProps, "meetingLink")}
                                                    value={values.meetingLink}
                                                    onChange={(e) => handleChange(e, "meetingLink")}
                                                    InputProps={{
                                                        className: classes.input,
                                                        disableUnderline: true
                                                    }}
                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        fontSize: "16px",

                                                    }}
                                                    error={inputError?.meetLink === undefined || inputError?.meetLink === "" ? false : true}
                                                    helperText={inputError.meetLink}
                                                    placeholder={"Enter Teams meeting link"}
                                                />
                                                {/* {formikErrorMessage(formikProps, "meetingLink")} */}
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                <Typography

                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        color: '#ffffff',
                                                        marginBottom: "0.6rem",
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Meeting password (optional)
                                                </Typography>

                                                <TextField
                                                    fullWidth
                                                    id="password"
                                                    name="password"
                                                    type={password ? "password" : "text"}
                                                    // className={formikErrorClassCheck(formikProps, "password")}
                                                    value={values.password}
                                                    onChange={(e) => handleChange(e, "password")}
                                                    InputProps={{
                                                        className: classes.input,
                                                        endAdornment:
                                                            <Button style={{ paddingRight: '0px' }}
                                                                onClick={() => showPassword(password)}>
                                                                {password ?
                                                                    <img src={crossEyeImage}
                                                                        style={{ height: '20px' }} />
                                                                    :
                                                                    <img src={eyeImage}
                                                                        style={{ height: '20px' }} />}
                                                            </Button>,

                                                        disableUnderline: true
                                                    }}
                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        fontSize: "16px",

                                                    }}
                                                // error={inputError?.password === undefined || inputError?.password === "" ? false : true}
                                                // helperText={inputError.password}
                                                />
                                                {/* {formikErrorMessage(formikProps, "password")} */}
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                <Typography

                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        color: '#ffffff',
                                                        marginBottom: "0.6rem",
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Meeting type
                                                </Typography>
                                                {/* @ts-ignore */}
                                                <Box className={classes.customSelectWrapper}>
                                                    <FormControl style={{ minWidth: "150px", width: "100%" }}
                                                        error={inputError?.type === undefined || inputError?.type === "" ? false : true}
                                                    >
                                                        <Select
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left"
                                                                },
                                                                PaperProps: {
                                                                    className: classes.paper
                                                                },
                                                                getContentAnchorEl: null
                                                            }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={meetingType}
                                                            className={classes.select}
                                                            label="Sort"
                                                            onChange={(e) => handleMeetingTypeChange(e)}
                                                            disableUnderline={true}
                                                            IconComponent={KeyboardArrowDownIcon}

                                                        // disabled={meetingTypesData.length === 0}
                                                        >
                                                            {/* <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"sales_call"}>Sales call</MenuItem>
                                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"work_meeting"}>Work Meeting</MenuItem>
                                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"classroom"}>Classroom</MenuItem> */}
                                                             {
                                                                meetingTypesData.length === 0 ?
                                                                    <Typography style={{ fontFamily: "SFPro-Regular", color: "#ffffff", paddingLeft: "10px" }}>No Meeting Type Found</Typography>
                                                                    :
                                                                    meetingTypesData.map((type:any) => (
                                                                        
                                                                       
                                                                        <MenuItem key={type.id} className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={type.id}>{type.name}</MenuItem>
                                                                        
                                                                    ))
                                                            }
                                                        </Select>
                                                        <span className={classes.customArrow}></span>
                                                        {inputError?.type && <FormHelperText>{inputError?.type}</FormHelperText>}
                                                    </FormControl>
                                                </Box>

                                                {/* {formikErrorMessage(formikProps, "password")} */}
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                <Typography

                                                    style={{
                                                        fontFamily: "SFPro-Regular",
                                                        color: '#ffffff',
                                                        marginBottom: "0.6rem",
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    Your Role
                                                </Typography>
                                                {/* @ts-ignore */}
                                                <Box className={classes.customSelectWrapper}>
                                                    <FormControl style={{ minWidth: "150px", width: "100%" }}
                                                        error={inputError?.role === undefined || inputError?.role === "" ? false : true}
                                                    >
                                                        <Select
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left"
                                                                },
                                                                PaperProps: {
                                                                    className: classes.paper
                                                                },
                                                                getContentAnchorEl: null
                                                            }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={roleType}
                                                            className={classes.select}
                                                            label="Sort"
                                                            onChange={(e) => handleRoleTypeChange(e)}
                                                            disableUnderline={true}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            disabled={!meetingType}
                                                        >
                                                            {/* <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"main_presenter"}>Main Presenter</MenuItem>
                                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"organiser"}>Organizer</MenuItem>
                                                            <MenuItem className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={"participant"}>Participant</MenuItem> */}
                                                            {
                                                                roleTypesData.length === 0 ?
                                                                    <Typography style={{ fontFamily: "SFPro-Regular", color: "#ffffff", paddingLeft: "10px" }}>No Role Found</Typography>
                                                                    :
                                                                    roleTypesData.map(role => (
                                                                        <MenuItem key={role.id} className={classes.menuItemStyle} style={{ fontFamily: "SFPro-Regular" }} value={role.id}>{role.name}</MenuItem>
                                                                    ))
                                                            }
                                                        </Select>
                                                        <span className={classes.customArrow}></span>
                                                        {inputError?.role && <FormHelperText>{inputError?.role}</FormHelperText>}
                                                    </FormControl>
                                                </Box>

                                                {/* {formikErrorMessage(formikProps, "password")} */}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container style={{ marginTop: "3rem" }}>
                                        <Grid item xs={12} sm={12}>
                                            {/* @ts-ignore */}
                                            <Box>
                                                <Button
                                                    onClick={() => fieldValidation()}
                                                    style={{
                                                        backgroundColor: "rgb(62 201 201)",
                                                        color: "#000",
                                                        padding: ".3rem 1.8rem",
                                                        textTransform: "initial",
                                                        fontFamily: "SFPro-Bold",
                                                        borderRadius: '6px',
                                                        fontSize: "16px",
                                                        width: '100%'
                                                    }}
                                                >
                                                    Join Meeting
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                            {/* );
                                }}
                            </Formik> */}
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default JoinMeetingPopup;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            width: "100%",
            height: "100%",
            border: "1px solid #ffffff",
            borderRadius: "7px",
            textDecoration: "none",
            fontSize: "1em",
            fontFamily: "SFPro-Regular",
            // backgroundColor: "transparent",
            color: '#ffffff !important',
            padding: "5px 1rem",
            "& > .MuiInputBase-input": {
                height: "100%",
            },
            "&  .MuiFormHelperText-root.Mui-error": {
                fontFamily: "SFPro-Regular",
            },
        },
        manage_background: {
            // backgroundColor: '#1f1f1f',
            // position: 'relative',
        },
        blur_effect: {
            position: 'absolute',
            width: '100%',
            height: '100vh',
            zIndex: 0,
            // backgroundImage: `url(${bgImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            // filter: 'blur(20px)',
            background: "rgb(0, 0, 0, 0.5)",
            backdropFilter: "blur(3px)"
        },
        main_wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: '496px',
            height: "70vh",

            borderRadius: '6px',
            boxShadow: '24px',
            padding: '10px 5px',
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        customSelectWrapper: {
            position: "relative",
            width: "100%",
            height: "50px",
            fontFamily: "SFPro-Regular",
            // boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important",
            backgroundColor: "#000 !important",
            borderColor: "#ffffff !important",

            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down('xs')]: {
                padding: '0 0 2px 5px'
            },
            // marginBottom: "10px"
        },
        menuItemStyle: {
            textAlign: "right",
            color: '#ffffff !important',
            backgroundColor: '#000',

        },
        paper: {
            backgroundColor: "#000",
            maxHeight: "110px",
            border: "1px solid #ffffff",
            overflowY: "auto"
        },
        customArrow: {
            position: "absolute",
            top: 0,
            right: 0,
            display: "block",
            backgroundColor: "#000",
            // borderRight: "1px solid #ffffff",
            // borderRadius: "0 6px 6px 0",
            // height: '100%',
            width: "3rem",
            pointerEvents: 'none',
        },
        select: {
            fontSize: "14px",
            fontFamily: "SFPro-Regular",
            border: "1px solid #ffffff",
            height: "43px",
            borderRadius: "5px 5px 5px 5px",
            backgroundColor: "#000",
            "& .MuiSelect-icon": {
                zIndex: 1,
                right: ".7rem",
                color: "#8991a4",
            },
            "& .MuiSelect-select": {
                color: "#fff !important",
                backgroundColor: "#000 !important",
                paddingLeft: "10px",
            },
            "&.Mui-disabled": {
                borderColor: '#5c5c5c',
                "& svg": {
                    color: '#5c5c5c'
                }
            },
            
        },

    })
);